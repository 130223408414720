<template>
  <div class="youtube">
    <youtube :video-id="videoId" :resize=true :player-vars="playerVars" ref="youtube" @ended="ended" class="youtube--iframe" @playing="playing" :width=1920 :height=1080 :fitParent=true></youtube>
    <div class="player-overlay"></div>
    <div class="header-buttons">
      <button class="header-button button is-medium is-rounded" @click="toggleVideo">
        <span class="icon is-medium">
          <i class="fas" v-bind:class="[!isPlaying ? 'fa-play': 'fa-pause']"></i>
        </span>
      </button>
      <button class="header-button button is-medium is-rounded" @click="toggleSound">
        <span class="icon is-medium">
          <i class="fas" v-bind:class="[!isMuted ? 'fa-volume-up': 'fa-volume-off']"></i>
        </span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      videoId: '',
      width: 1920,
      height: 1080,
      isMuted: false,
      isPlaying: false,
      playerVars: {
        autoplay: 0, autohide: 1, modestbranding: 1, rel: 0, showinfo: 0, controls: 0,
        disablekb: 1, enablejsapi: 0, iv_load_policy: 3, fs: 1, mute: 0,
        loop: 1, listType: 'playlist'
      }
    }
  },
  props: {
    url: String
  },
  methods: {
    toggleVideo() {
      if (this.isPlaying) {
        this.player.pauseVideo();
        this.isPlaying = false;
      } else {
        this.player.playVideo();
        this.isPlaying = true;
      }
    },
    toggleSound() {
      if (this.isMuted) {
        this.player.unMute();
        this.isMuted = false;
      } else {
        this.player.mute();
        this.isMuted = true;
      }
    },
    getId () {
      return this.$youtube.getIdFromUrl(this.url)
    },
    ended() {
      if (this.isPlaying) {
        this.player.playVideo()
      }
    },
    playing() {
      this.isPlaying = true;
    }
  },
  computed: {
    player() {
      return this.$refs.youtube.player
    }
  },
  mounted: function (params) {
    this.videoId = this.getId();
    this.player.playVideo();
  }
}
</script>

<style lang="scss">
@import "node_modules/bulma/sass/utilities/_all.sass";

$blue: #3c71b4;

.youtube--iframe {
  width: 80vw;
  height: 45vw;
  z-index: 0;
  margin: auto 0;
}

.header-buttons {
  position: absolute;
  z-index: 4;
  right: 10vw;
  top: 47vw;
  mix-blend-mode: lighten;

  .header-button {
    &:hover {
      background: #eee;
    }
  }
}

</style>
