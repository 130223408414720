import ScrollMagic from 'scrollmagic';

import './main.scss';
import { Linear, TimelineMax, TweenMax } from 'gsap';
import 'scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap.js';
import 'particles.js';
import particlesConfig from './particlesjs-config.js';
import { throttle } from 'lodash';
import CookiesEuBanner from 'cookies-eu-banner';
import Vue from 'vue';
import HeaderVideo from "./scripts/HeaderVideo";
import VueYoutube from 'vue-youtube';


var banner = new CookiesEuBanner(function () {
  (function (b, o, i, l, e, r) {
  b.GoogleAnalyticsObject = l; b[l] || (b[l] =
    function () { (b[l].q = b[l].q || []).push(arguments) }); b[l].l = +new Date;
    e = o.createElement(i); r = o.getElementsByTagName(i)[0];
    e.src = 'https://www.google-analytics.com/analytics.js';
    r.parentNode.insertBefore(e, r)
  }(window, document, 'script', 'ga'));
  ga('create', 'UA-29783754-9', 'auto'); ga('send', 'pageview'); ga('set', 'anonymizeIp', true);
});

window.toggleBox = function (id, trigger) {
  var el = document.getElementById(id);
  el.classList.toggle('box--is-shown');
  if (trigger) {
    trigger.classList.toggle('box--is-shown');
  }
}

particlesJS('bubbles', particlesConfig);

function magic(controller) {

  var scrollContent = document.getElementById('ScrollContent');
  var scrollContainer = document.getElementById('ScrollContainer');

  var contentHeight = scrollContent.offsetHeight;
  var containerHeight = scrollContainer.offsetHeight;

  var timeline = new TimelineMax();


  var SphereTween = {
    scale: '0.33',
    top: '40vh',
    ease: Linear.easeNone
  }

  if (window.matchMedia) {
    const portrait = '(orientation: portrait)';
    const mobile = '(max-width: 769px)';
    var mediaQueryPortrait = window.matchMedia(portrait);
    var mediaQueryMobile = window.matchMedia(mobile);
    if (mediaQueryPortrait.matches || mediaQueryMobile.matches) {
      SphereTween = {
        scale: '1.33',
        top: '40vh',
        ease: Linear.easeNone
      }
    }
  }
  // sphere scene
  new ScrollMagic.Scene({
    duration: contentHeight - containerHeight,
  })
    .setTween("#SphereStream", 50, {
      y: contentHeight - containerHeight,
      ease: Linear.easeNone
    })
    .addTo(controller);


  // sphere scene
  new ScrollMagic.Scene({
    duration: containerHeight,
    offset: 0,
  }).setTween("#SphereStream", 50, SphereTween).addTo(controller);


  // background scenes
  var SceneBG = new ScrollMagic.Scene({
    offset: 0,
    duration: containerHeight,
  })
    .setTween("#background", 1, {
      y: -containerHeight,
      ease: Linear.easeNone
    })
    .addTo(controller);

  // var SceneParticles = new ScrollMagic.Scene({
  //   offset: 0,
  //   duration: contentHeight - containerHeight,
  // })
  // .setTween("#bubbles", 1, {
  //   y: -containerHeight,
  //   ease: Linear.easeNone
  // })
  // .addTo(controller);


  // strands scenes
  var SceneStrangBG = new ScrollMagic.Scene({
    offset: 0,
    duration: contentHeight,
  })
    .setTween("#strandsBG", 1, {
      y: -containerHeight / 4,
      ease: Linear.easeNone
    })
    .addTo(controller);

  var SceneStrangMG = new ScrollMagic.Scene({
    offset: 0,
    duration: contentHeight,
  })
    .setTween("#strandsMG", 1, {
      y: -containerHeight / 2,
      ease: Linear.easeNone
    })
    .addTo(controller);

  var SceneStrangFG = new ScrollMagic.Scene({
    offset: 0,
    duration: contentHeight,
  })
    .setTween("#strandsFG", 1, {
      y: -containerHeight,
      ease: Linear.easeNone
    })
    .addTo(controller);


  var sections = document.getElementsByClassName('hero-body--animated');

  Array.prototype.forEach.call(sections, element => {
    // sphere scene
    new ScrollMagic.Scene({
        duration: containerHeight / 4,
        triggerElement: element
      })
      .setTween(element, 1, {
        filter: 'blur(0px)',
        ease: Linear.easeNone
      })
      .addTo(controller);

    new ScrollMagic.Scene({
        duration: containerHeight / 4,
        offset: 3 * containerHeight / 4,
        triggerElement: element
      })
      .setTween(element, 1, {
        filter: 'blur(20px)',
        ease: Linear.easeNone
      })
      .addTo(controller);
  });

}

var controller = new ScrollMagic.Controller({
  container: '#ScrollContainer',
  refreshInterval: 33
});

magic(controller);

document.addEventListener('DOMContentLoaded', () => {
  Vue.use(VueYoutube);

  var HeaderVideoContainer = document.getElementById('HeaderVideo');
  if (HeaderVideoContainer) {
    new Vue({
      el: '#HeaderVideo',
      components: {
        HeaderVideo
      }
    });
  }
});

window.addEventListener('resize', throttle(function (params) {
  if (controller) {
    controller.destroy(true);

    controller = new ScrollMagic.Controller({
      container: '#ScrollContainer',
      refreshInterval: 100
    });

    magic(controller);
  }
}, 100));
